// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,600,600i,500,500i,700,700i,800);
@import url("https://fonts.googleapis.com/css?family=Open+Sans:200,300,400,400i,500,600,700%7CMerriweather:300,300i%7CMaterial+Icons");

// Icon Font
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);

// Bootstrap 3
@import "bootstrap.scss";

// Theme
@import "stack-interface.scss";
@import "socicon.scss";
@import "lightbox.scss";
@import "flickity.scss";
@import "iconsmind.scss";
@import "theme.scss";

/**
 * Form Elements
 */
input[type="checkbox"]{
    -webkit-appearance: checkbox;
    height: auto;
    width: auto;
}

#terms_checkbox {
    display: inline;
    margin-right: 10px;
}

.tooltip > .tooltip-inner {
    background-color: #2e66a0;
    color: #FFFFFF;
    padding: 5px;
    font-size: 12px;
    width: 300px;
    margin-left: -145px;
    margin-top: 10px;
}

/* Tooltip */
.tooltip > .tooltip-arrow {
    display: none;
    background-color: #2e66a0;
}

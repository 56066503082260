body:after {
    display: none
}

.lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #000;
    filter: alpha(Opacity=80);
    opacity: .8;
    display: none
}

.lightbox {
    position: fixed;
    top: 50% !important;
   	transform: translate3d(0,-50%,0);
    left: 0;
    width: 100%;
    z-index: 10000;
    text-align: center;
    line-height: 0;
    font-weight: 400
}

.lightbox .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px
}

.lightbox a img {
    border: none
}

.lb-outerContainer {
    position: relative;
    background-color: #222;
    *zoom: 1;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}

.lb-outerContainer:after {
    content: "";
    display: table;
    clear: both
}

.lb-container {
    padding: 4px
}

.lb-loader {
    position: absolute;
    top: 43%;
    left: 0;
    height: 25%;
    width: 100%;
    text-align: center;
    line-height: 0
}

.lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
}

.lb-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10
}

.lb-container>.nav {
    left: 0
}

.lb-nav a {
    outline: 0;
}

.lb-next,
.lb-prev {
    height: 100%;
    cursor: pointer;
    display: block
}

.lb-nav a.lb-prev {
    width: 34%;
    left: 0;
    float: left;
    filter: alpha(Opacity=0);
    opacity: .5;
    -webkit-transition: opacity .6s;
    -moz-transition: opacity .6s;
    -o-transition: opacity .6s;
    transition: opacity .6s
}

.lb-nav a.lb-prev:hover {
    filter: alpha(Opacity=100);
    opacity: 1
}

.lb-nav a.lb-next {
    width: 64%;
    right: 0;
    float: right;
    filter: alpha(Opacity=0);
    opacity: .5;
    -webkit-transition: opacity .6s;
    -moz-transition: opacity .6s;
    -o-transition: opacity .6s;
    transition: opacity .6s
}

.lb-nav a.lb-next:hover {
    filter: alpha(Opacity=100);
    opacity: 1
}

.lb-prev:after,.lb-next:after{
	content: '\2190';
	position: absolute;
	top: 50%;
	color: #fff;
	font-size: 24px;
}
.lb-prev:after{
	left: -60px;
}
.lb-next:after{
	right: -60px;
	content: '\2192';
}

.lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    min-height: 35px;
    *zoom: 1;
    width: 100%;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.lb-dataContainer:after {
    content: "";
    display: table;
    clear: both
}

.lb-data {
    padding: 0 4px;
    color: #ccc
}

.lb-data .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em
}

.lb-data .lb-caption {
    font-size: 13px;
    font-weight: 700;
    line-height: 1em
}

.lb-data .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999
}

.lb-data .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    text-align: right;
    outline: 0;
    filter: alpha(Opacity=70);
    opacity: .7;
    -webkit-transition: opacity .2s;
    -moz-transition: opacity .2s;
    -o-transition: opacity .2s;
    transition: opacity .2s
}

.lb-data .lb-close:hover {
    cursor: pointer;
    filter: alpha(Opacity=100);
    opacity: 1
}
